<template>
  <el-dialog :lock-scroll="true" v-model="show">
    <template v-slot:title>
      <div style="border-bottom: #e0e0e0 1px solid; padding: 20px;">
        <div class="modal-title">{{ title }}</div>
      </div>
    </template>
    <template v-slot:footer>
      <div style="border-top: #e0e0e0 1px solid; padding: 20px; display: flex; justify-content: space-between;">
        <el-button type="danger" plain @click="doCancel()">{{ KT('cancel') }}</el-button>
        <el-button type="primary" @click="doSave()">{{ KT('save') }}</el-button>
      </div>
    </template>

    <el-tabs v-model="tab">
     <el-tab-pane :label="KT('driver.driver')" name="first">
  <el-form label-width="120px" label-position="top">
    <div style="display: flex; align-items: center;">
      <div>
        <driver-image-upload 
          v-model="formData.base64Image" 
          :url="(formData.id)?'/tarkan/assets/images/drivers/'+formData.id+'.png?t='+Date.now():null"
          :driver-id="formData.id"
          :key="'driver-image-'+formData.id">
        </driver-image-upload>
      </div>
      <div style="flex: 1; margin-left: 30px;">
        <el-form-item :label="KT('driver.name')">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item :label="KT('driver.uniqueId')">
          <el-input v-model="formData.uniqueId"></el-input>
        </el-form-item>
      </div>
    </div>

    <!-- Línea con CPF, CNH Number y CNH Category -->
    <div style="display: flex; justify-content: space-between;">
      <el-form-item :label="KT('driver.cnhNumber')" style="flex: 1; margin-right: 10px;">
        <el-input v-model="formData.attributes.cnhNumber"></el-input>
      </el-form-item>
      <el-form-item :label="KT('driver.cpf')" style="flex: 1; margin-right: 10px;">
        <el-input v-model="formData.attributes.cpf"></el-input>
      </el-form-item>
      <el-form-item :label="KT('driver.cnhCategory')" style="flex: 1;">
        <el-select v-model="formData.attributes.cnhCategory">
          <el-option label="A" value="A"></el-option>
          <el-option label="B" value="B"></el-option>
          <el-option label="C" value="C"></el-option>
          <el-option label="D" value="D"></el-option>
          <el-option label="E" value="E"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="KT('driver.cnhState')" style="flex: 1;">
  <el-select v-model="formData.attributes.state">
    <el-option label="AC" value="AC"></el-option>
    <el-option label="AL" value="AL"></el-option>
    <el-option label="AP" value="AP"></el-option>
    <el-option label="AM" value="AM"></el-option>
    <el-option label="BA" value="BA"></el-option>
    <el-option label="CE" value="CE"></el-option>
    <el-option label="DF" value="DF"></el-option>
    <el-option label="ES" value="ES"></el-option>
    <el-option label="GO" value="GO"></el-option>
    <el-option label="MA" value="MA"></el-option>
    <el-option label="MT" value="MT"></el-option>
    <el-option label="MS" value="MS"></el-option>
    <el-option label="MG" value="MG"></el-option>
    <el-option label="PA" value="PA"></el-option>
    <el-option label="PB" value="PB"></el-option>
    <el-option label="PR" value="PR"></el-option>
    <el-option label="PE" value="PE"></el-option>
    <el-option label="PI" value="PI"></el-option>
    <el-option label="RJ" value="RJ"></el-option>
    <el-option label="RN" value="RN"></el-option>
    <el-option label="RS" value="RS"></el-option>
    <el-option label="RO" value="RO"></el-option>
    <el-option label="RR" value="RR"></el-option>
    <el-option label="SC" value="SC"></el-option>
    <el-option label="SP" value="SP"></el-option>
    <el-option label="SE" value="SE"></el-option>
    <el-option label="TO" value="TO"></el-option>
  </el-select>
</el-form-item>

    </div>

    <!-- Línea con fechas -->
    <div style="display: flex; justify-content: space-between;">
      <el-form-item :label="KT('driver.birthDate')" style="flex: 1; margin-right: 10px;">
        <el-date-picker v-model="formData.attributes.birthDate" type="date" format="DD-MM-YYYY" value-format="DD-MM-YYYY"></el-date-picker>
      </el-form-item>
      <el-form-item :label="KT('driver.firstLicenseDate')" style="flex: 1; margin-right: 10px;">
        <el-date-picker v-model="formData.attributes.firstLicenseDate" type="date" format="DD-MM-YYYY" value-format="DD-MM-YYYY"></el-date-picker>
      </el-form-item>
      <el-form-item :label="KT('driver.cnhValidity')" style="flex: 1;">
        <el-date-picker v-model="formData.attributes.cnhValidity" type="date" format="DD-MM-YYYY" value-format="DD-MM-YYYY"></el-date-picker>
      </el-form-item>
    </div>

    <!-- Otros campos -->
    <el-form-item :label="KT('driver.cnhFiliation')">
      <el-input v-model="formData.attributes.cnhFiliation"></el-input>
    </el-form-item>
    <el-form-item :label="KT('driver.cnhObservations')">
      <el-input v-model="formData.attributes.cnhObservations"></el-input>
    </el-form-item>

  </el-form>
</el-tab-pane>
<el-tab-pane :label="$t('user.accountAddress')" name="address">
  <!-- Contenedor de dirección (ya existente) -->

<!-- Título de address -->
<div style="margin-top: 20px; padding-bottom: 10px; font-weight: bold; border-bottom: 1px solid #ddd;">
    {{$t('user.addrerss')}}
  </div>


  <div style="display: flex; justify-content: space-between; gap: 20px;">
    <el-form-item :label="$t('user.cep')" prop="cep" style="flex: 0.7;">
      <el-input v-model="formData.attributes['cep']" @blur="fetchAddressByCEP" placeholder="Digite o CEP"></el-input>
    </el-form-item>
    <el-form-item :label="$t('user.rua')" style="flex: 3.0;">
      <el-input v-model="formData.attributes['rua']" disabled></el-input>
    </el-form-item>
    <el-form-item :label="$t('user.hausenumber')" style="flex: 0.5;">
      <el-input v-model="formData.attributes['hausenumber']"></el-input>
    </el-form-item>
  </div>

  <div style="display: flex; justify-content: space-between; gap: 20px;">
    <el-form-item :label="$t('user.complemento')" prop="cep" style="flex: 1;">
      <el-input v-model="formData.attributes['complemento']"></el-input>
    </el-form-item>
  </div>

  <div style="display: flex; justify-content: space-between; gap: 20px;">
    <el-form-item :label="$t('user.bairro')" style="flex: 1;">
      <el-input v-model="formData.attributes['bairro']" disabled></el-input>
    </el-form-item>
    <el-form-item :label="$t('user.cidade')" style="flex: 1;">
      <el-input v-model="formData.attributes['cidade']" disabled></el-input>
    </el-form-item>
    <el-form-item :label="$t('user.estado')" style="flex: 1;">
      <el-input v-model="formData.attributes['estado']" disabled></el-input>
    </el-form-item>
  </div>

  <!-- Título de Comunicación -->
  <div style="margin-top: 20px; padding-bottom: 10px; font-weight: bold; border-bottom: 1px solid #ddd;">
    {{$t('user.communication')}}
  </div>

  <!-- Contenedor de Comunicación -->
  <div style="display: flex; flex-direction: column; gap: 15px; padding-top: 10px;">
    <!-- Fila Particular -->
    <div style="display: flex; justify-content: space-between; gap: 20px;">
      <el-form-item :label="$t('user.phonePersonal')" style="flex: 1;">
        <el-input v-model="formData.attributes['phonePersonal']" placeholder="Digite o número de telefone particular"></el-input>
      </el-form-item>
      <el-form-item :label="$t('user.whatsappPersonal')" style="flex: 1;">
        <el-input v-model="formData.attributes['whatsappPersonal']" placeholder="Digite o número do WhatsApp pessoal"></el-input>
      </el-form-item>
      <el-form-item :label="$t('user.emailPersonal')" style="flex: 1;">
        <el-input v-model="formData.attributes['emailPersonal']" placeholder="Digite o e-mail particular"></el-input>
      </el-form-item>
    </div>

    <!-- Fila Comercial -->
    <div style="display: flex; justify-content: space-between; gap: 20px;">
      <el-form-item :label="$t('user.phoneCommercial')" style="flex: 1;">
        <el-input v-model="formData.attributes['phoneCommercial']" placeholder="Digite o número de telefone comercial"></el-input>
      </el-form-item>
      <el-form-item :label="$t('user.whatsappCommercial')" style="flex: 1;">
        <el-input v-model="formData.attributes['whatsappCommercial']" placeholder="Digite o número do WhatsApp comercial"></el-input>
      </el-form-item>
      <el-form-item :label="$t('user.emailCommercial')" style="flex: 1;">
        <el-input v-model="formData.attributes['emailCommercial']" placeholder="Digite o e-mail comercial"></el-input>
      </el-form-item>
    </div>
  </div>
</el-tab-pane>

      <el-tab-pane :label="KT('device.devices')" name="devices">
        <TabDevices ref="tabDevicesRef" />
      </el-tab-pane>
      
      <el-tab-pane :label="KT('driver.qrCode')" name="qrCode">
        <div v-if="store.getters['server/getAttribute']('tarkan.enableQrDriverId',false)" style="margin-top: 15px;display: flex;margin-bottom: 3px;padding: 7px;border-radius: 3px;background: var(--el-color-info-light); align-content: space-between;justify-content: space-between">
          <div style="font-weight: bold;font-size: 16px;">{{$t('driver.qrDriver.enable')}}</div>
          <el-switch
            v-model="formData['attributes']['tarkan.enableQrCode']"
            :inactive-text="$t('disabled')"
            :active-text="$t('enabled')"
            :active-value="true"
            :inactive-value="false"
          ></el-switch>
        </div>
        <div style="border: 1px solid;margin-top: -4px;margin-bottom: 10px;padding: 7px;border-radius: 0px 0px 5px 5px; border-color: var(--el-color-info-light);" v-if="formData['attributes']['tarkan.enableQrCode']">
          <el-form-item :label="KT('driver.qrDriver.username')">
            <el-input v-model="formData['attributes']['tarkan.driverUsername']"></el-input>
          </el-form-item>
          <el-form-item :label="KT('driver.qrDriver.password')">
            <el-input v-model="formData['attributes']['tarkan.driverPassword']" type="password"></el-input>
          </el-form-item>
          <div style="margin-top: 15px;font-weight: bold;border-bottom: silver 1px dotted;padding-bottom: 5px;">
            {{$t('driver.qrDriver.behaviorRules')}}
          </div>
          <div style="display: flex;margin-top: 5px;align-content: space-between;justify-content: space-between;padding-top: 3px;padding-bottom: 3px;border-bottom: 1px dotted;border-color: var(--el-color-info-light);">
            <div>{{$t('driver.qrDriver.unlockDevice')}}</div>
            <el-switch
              v-model="formData['attributes']['tarkan.driverUnlockDevice']"
              :inactive-text="$t('no')"
              :active-text="$t('yes')"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </div>
          <div style="display: flex;margin-top: 5px;align-content: space-between;justify-content: space-between;padding-top: 3px;padding-bottom: 3px;border-bottom: 1px dotted;border-color: var(--el-color-info-light);">
            <div>{{$t('driver.qrDriver.autoLogout')}}</div>
            <el-switch
              v-model="formData['attributes']['tarkan.driverAutoLogout']"
              :inactive-text="$t('no')"
              :active-text="$t('yes')"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </div>
          <el-form-item :label="KT('driver.qrDriver.autoLogoutTimeout')" prop="name">
            <el-select size="large" v-model="formData['attributes']['tarkan.autoLogoutTimeout']">
              <el-option :value="1" :label="'1 '+KT('minute')">1 {{KT('minute')}}</el-option>
              <el-option :value="2" :label="'2 '+KT('minutes')">2 {{KT('minutes')}}</el-option>
              <el-option :value="3" :label="'3 '+KT('minutes')">3 {{KT('minutes')}}</el-option>
              <el-option :value="4" :label="'4 '+KT('minutes')">4 {{KT('minutes')}}</el-option>
              <el-option :value="5" :label="'5 '+KT('minutes')">5 {{KT('minutes')}}</el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-tab-pane>

      <el-tab-pane :label="KT('attribute.attributes')" name="fourth">
        <tab-attributes v-model="formData.attributes" :type="'driver'"></tab-attributes>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>


</template>


<script setup>

import 'element-plus/es/components/input/style/css'
import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/switch/style/css'
import 'element-plus/es/components/select/style/css'
import 'element-plus/es/components/option/style/css'
import 'element-plus/es/components/dialog/style/css'
import 'element-plus/es/components/tab-pane/style/css'
import 'element-plus/es/components/tabs/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/checkbox/style/css'

import {ElDialog,ElSelect,ElOption,ElMessage,ElTabs,ElTabPane,ElForm,ElSwitch,ElFormItem,ElButton,ElInput,ElNotification} from "element-plus";
import { ElDatePicker } from 'element-plus';

//import axios from "axios";
import { ref, defineExpose , watch } from "vue";
import { useStore } from "vuex";
import KT from "../../func/kt";
import TabDevices from "./tab-devices";
import TabAttributes from "./tab-attributes";
const tabDevicesRef = ref(null);

import DriverImageUpload from "@/tarkan/components/driver-image-upload.vue"

const store = useStore();
const title = ref("");
const show = ref(false);
const tab = ref("first");

const defaultDriverData = {
  id: 0,
  attributes: {},
  name: "",
  uniqueId: "",
};

// Variables para la imagen
const selectedFile = ref(null);
const imageUrl = ref(null);

const formData = ref({ ...defaultDriverData });

const newDriver = () => {
  tab.value = "first";
  title.value = KT("driver.newDriver");
  formData.value = { ...defaultDriverData };
  selectedFile.value = null;
  imageUrl.value = null;
  show.value = true;
};

const editDriver = (id) => {
  if (id === 0) {
    ElMessage.error(KT("driver.selectError"));
    return false;
  }

  title.value = KT("driver.editDriver");
  tab.value = "first";
  formData.value = { ...defaultDriverData };
  const driver = store.getters["drivers/getDriver"](id);

  Object.keys(defaultDriverData).forEach((key) => {
    formData.value[key] = driver[key];
  });

  show.value = true;
};



const doCancel = () => {
  show.value = false;
};

const doSave = () => {
  ElNotification({
    title: KT("info"),
    message: KT("driver.saving"),
    type: "info",
  });

  store
    .dispatch("drivers/save", formData.value)
    .then(() => {
      // Ya no usamos la función de caché
      
      show.value = false;
      ElNotification({
        title: KT("success"),
        message: KT("driver.saved"),
        type: "info",
      });
    
    if (tabDevicesRef.value && typeof tabDevicesRef.value.clear === "function") {
        tabDevicesRef.value.clear();
      }
    })




    .catch(() => {
      ElMessage.error(KT("driver.saveError"));
    });
};


defineExpose({
  newDriver,
  editDriver,
});
const fetchAddressByCEP = async () => {
  const cep = formData.value.attributes['cep']; // Utiliza el valor de formData

  if (cep) {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      if (!data.erro) {
        // Guardar datos de dirección en formData.attributes
        formData.value.attributes['rua'] = data.logradouro;
        formData.value.attributes['bairro'] = data.bairro;
        formData.value.attributes['complemento'] = data.complemento;
        formData.value.attributes['cidade'] = data.localidade;
        formData.value.attributes['estado'] = data.uf;
      } else {
        alert('CEP não encontrado');
        resetAddressFields();
      }
    } catch (error) {
      console.error('Erro ao buscar o endereço:', error);
      alert('Erro ao buscar o endereço. Tente novamente.');
    }
  }
};

watch(() => formData.value.attributes['cep'], (newVal) => {
  if (!newVal) {
    resetAddressFields();
  }
});
// Función para limpiar los campos de dirección
const resetAddressFields = () => {
  formData.value.attributes['cep'] = '';
  formData.value.attributes['rua'] = '';
  formData.value.attributes['bairro'] = '';
  formData.value.attributes['complemento'] = '';
  formData.value.attributes['cidade'] = '';
  formData.value.attributes['estado'] = '';
};





</script>







<style scoped>
  .el-select.el-select--large {
    width: 100%;
  }

  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0px !important;
  }

  .el-dialog__footer {
    margin-top: 20px;
  }

  .el-tabs__nav-wrap {
    padding-left: 20px;
    padding-right: 20px;
  }

  .el-tabs__content {
    padding-left: 20px;
    padding-right: 20px;
  }
 .avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
  border-radius: 50%; /* Para un efecto redondeado */
}

.el-upload {
  width: 178px;
  height: 178px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
